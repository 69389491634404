import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useLayoutEffect, useMemo, useState } from "react";

export const useIsMobile = () => {
  const breakpoints = useBreakpoint();
  const isMobile = useMemo(() => !breakpoints.lg, [breakpoints]);

  return isMobile;
}

export const useIsXsMobile = () => {
  const breakpoints = useBreakpoint();
  const isXsMobile = useMemo(() => breakpoints.xs, [breakpoints]);

  return isXsMobile;
}

export const useWindowSize = () => {
  const [windowSize, setSize] = useState([0, 0]);

  useLayoutEffect(() => {
    const updateSize = () => {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return windowSize;
}