const dev = {
  API_ENDPOINT_URL: "http://localhost:9000",
  STRIPE_PUBLIC_KEY:
    "pk_test_51HKQLWFc5r6Q3WLxCup3aCTFZXyTOvII5VSR0Y4sj3t3XRN2GxaCjtLsG5TyDHegQVoWSv9D8IGSWH3kICjrxSha003mNItapM",
  ESHOP_URL: "http://localhost:3001",
};

const prod = {
  API_ENDPOINT_URL: "https://api.lama.co",
  ESHOP_URL: "https://lama.co",
};

const test = {
  API_ENDPOINT_URL: "https://api-staging999.lama.co",
  ESHOP_URL: process.env.REACT_APP_ESHOP_URL || "https://staging-999.lama.co",
  STRIPE_PUBLIC_KEY:
    "pk_test_51HKQLWFc5r6Q3WLxCup3aCTFZXyTOvII5VSR0Y4sj3t3XRN2GxaCjtLsG5TyDHegQVoWSv9D8IGSWH3kICjrxSha003mNItapM",
};

const getEnv = () => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case "test":
      return test;
    case "production":
      return prod;
    case "dev":
      return dev;
    default:
      return prod;
  }
};

export const env = getEnv();
