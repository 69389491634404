import {
  AdminPostProductsProductVariantsReq,
  AdminPostProductsProductVariantsVariantReq,
  Product,
} from "@medusajs/medusa";
import { AxiosResponse } from "axios";
import {
  adminProductKeys,
  adminStoreKeys,
  useAdminProducts,
  useAdminStore,
  useCart,
  useProductTypes,
} from "medusa-react";
import { useMemo } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ProductService from "services/ProductService";
import fetch from "auth/FetchInterceptor";
import { ProductStatus } from "types/product";
import { IS_PAAGE } from "configs/AppConfig";

export function useUpdateProduct() {
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse<Product>, Error, Partial<Product>>({
    mutationFn: async ({ id, ...product }) => {
      return ProductService.update(id, product);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(adminProductKeys.list());
      queryClient.invalidateQueries(adminProductKeys.all);
    },
  });
}

export function useCreateEshopVariant(product_id) {
  return useMutation(
    (payload: {
      studio_variant: AdminPostProductsProductVariantsReq;
      eshop_variant: AdminPostProductsProductVariantsReq;
    }) =>
      fetch(`/admin/products/${product_id}/eshop-variants`, {
        method: "POST",
        data: payload,
      })
  );
}
export function useUpdateEshopVariant(product_id) {
  return useMutation(
    ({
      variant_id,
      ...payload
    }: {
      studio_variant: AdminPostProductsProductVariantsVariantReq;
      eshop_variant: AdminPostProductsProductVariantsVariantReq;
      variant_id: string;
    }) =>
      fetch(`/admin/products/${product_id}/eshop-variants/${variant_id}`, {
        method: "POST",
        data: payload,
      })
  );
}

export function useIsForbiddenToAddProduct() {
  const { store } = useAdminStore();
  const { count: eshopProductsCount, isLoading: eshopPorductsLoading } =
    useAdminProducts(
      {
        origin_type: "eshop",
        limit: 1,
        fields: "store.id,id,status",
        status: ProductStatus.PUBLISHED as any,
        expand: "",
      },
      { enabled: true }
    );

  const isForbiddenToaddProduct = useMemo(() => {
    if (!eshopPorductsLoading && eshopProductsCount !== undefined) {
      return (
        !!store?.subscription?.max_product_count &&
        eshopProductsCount >= store?.subscription?.max_product_count &&
        !IS_PAAGE
      );
    } else {
      return true;
    }
  }, [eshopProductsCount, eshopPorductsLoading, store]);
  return isForbiddenToaddProduct;
}

export function useCertificates() {
  const { product_types } = useProductTypes();
  const type = product_types?.filter((type) => type.value === "certificate")[0]
    .id as string;
  const { isLoading, isFetching, products, refetch } = useAdminProducts({
    type_id: [type],
  });

  return { isLoading, isFetching, certificates: products, refetch };
}

export function useAutoCertificates() {
  const { product_types } = useProductTypes();
  const type = product_types?.filter(
    (type) => type.value === "auto-certificate"
  )[0].id as string;
  const { isLoading, isFetching, products } = useAdminProducts({
    type_id: [type],
  });

  return { isLoading, isFetching, certificates: products };
}

export function useThankYouCards() {
  const { product_types } = useProductTypes();
  const type = product_types?.filter(
    (type) => type.value === "thank-you-card"
  )[0].id as string;
  const { isLoading, isFetching, products, refetch } = useAdminProducts(
    { type_id: [type] },
    { keepPreviousData: true }
  );

  return { isLoading, isFetching, thankYouCards: products, refetch };
}

//product type flaw
//FLAW
export function usePackagingLogoLineItem(enabled = true) {
  const { cart } = useCart();
  const { product_types } = useProductTypes({}, { enabled: enabled });

  const packagingLogoLineItem = useMemo(() => {
    const type = product_types?.filter(
      (type) => type.value === "packaging-logo"
    )[0];
    return cart?.items?.find(
      (item) => item.variant?.product?.type_id === type?.id
    );
  }, [cart?.items, product_types]);

  return packagingLogoLineItem;
}

export function useGoodiesLineItem() {
  const { cart } = useCart();

  const goodiesLineItem = useMemo(() => {
    return cart?.items?.find(
      (item) => item.variant?.id === process.env.GOODY_VARIANT_ID
    );
  }, [cart?.items]);

  return goodiesLineItem;
}

//product type flaw
//FLAW
export function useTYCLineItem(enabled = true) {
  const { cart } = useCart();
  const { product_types } = useProductTypes({}, { enabled: enabled });

  const useTYCLineItem = useMemo(() => {
    const type = product_types?.filter(
      (type) => type.value === "thank-you-card"
    )[0];
    return cart?.items?.find(
      (item) => item.variant?.product?.type_id === type?.id
    );
  }, [cart?.items, product_types]);

  return useTYCLineItem;
}

export function useRemoveBackground() {
  const queryClient = useQueryClient();
  return useMutation<any, Error, any>({
    mutationFn: async (url: string) =>
      await fetch(`/admin/products/remove-background`, {
        method: "POST",
        data: { url },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(adminStoreKeys.all);
    },
  });
}

export function useGenerateProductStoryVideo(productId: string) {
  return useMutation<{}, Error>({
    mutationFn: async () =>
      await fetch(`/admin/products/${productId}/shareable-video`, {
        method: "POST",
      }),
  });
}
