import React from "react";
import lazyWithRetry from "routes/lazyWithRetry";
import { IS_PAAGE } from "./AppConfig";

export const AUTH_PREFIX_PATH = "/auth";
export const REDIRECT_URL_KEY = "redirect";
export const AUTHENTICATED_ENTRY = IS_PAAGE ? `/my-page` : `/home`;
export const UNAUTHENTICATED_ENTRY = "/login";

// Define routes accessible only to non-authenticated users
export const unauthenticatedRoutes = [
  {
    key: "auth-routes",
    path: "/auth/*",
    element: lazyWithRetry(
      () => import("routes/unauthenticated-routes"),
      "auth"
    ),
  },
];

// Define third party redirect routes accessible to all users
export const thirdPartyRedirectRoutes = [
  {
    key: "oauth-routes",
    path: "/oauth/*",
    element: lazyWithRetry(
      () => import("views/third-party-views/oauth/oauth-callback"),
      "oauth"
    ),
  },
  {
    key: "stripe-routes",
    path: "/stripe/*",
    element: lazyWithRetry(
      () => import("routes/third-parties-redirect-routes/stripe"),
      "stripe"
    ),
  },
  {
    key: "paypal-routes",
    path: "/paypal/*",
    element: lazyWithRetry(
      () => import("routes/third-parties-redirect-routes/paypal"),
      "paypal"
    ),
  },
  {
    key: "referal-routes",
    path: "/auth/check-email-code/:token",
    element: lazyWithRetry(
      () => import("routes/third-parties-redirect-routes/referal"),
      "referal"
    ),
  },
];

// Define public routes accessible to all users
export const publicRoutes = [
  {
    key: "tracking-routes",
    path: "/tracking/*",
    element: lazyWithRetry(
      () => import("routes/public-routes/tracking"),
      "tracking"
    ),
  },
];

// Define routes accessible only to authenticated users
export const protectedRoutes = [
  {
    key: "setup-routes",
    path: "/store/start/*",
    element: lazyWithRetry(
      () => import("routes/protected-routes/setup-routes"),
      "setup-routes"
    ),
  },
  {
    key: "onboarding-routes",
    path: "/onboarding/*",
    element: lazyWithRetry(
      () => import("routes/protected-routes/onboarding-routes"),
      "onboarding"
    ),
  },
  {
    key: "protected-routes",
    path: "/*",
    element: lazyWithRetry(() => import("layouts/app-layout"), "app-layout"),
  },
];
